import {request} from "@/network/request";

// 新增博客文章
export function PostAddBlog(token,data) {
    return request({
        method: 'post',
        url: '/api/Blog/PostAddBlog',
        headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
        data
    })
}

//获取标签所有数据
export function GetBlogTagsBaseList(token) {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogTagsBaseList',
        headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token}
    })
}

// 新增标签
export function PostBlogTagsBaseAdd(token,data) {
    return request({
        method: 'post',
        url: '/api/Blog/PostBlogTagsBaseAdd',
        headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
        data
    })
}

//获取分类所有数据
export function GetBlogClassifyBaseList(token) {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogClassifyBaseList',
        headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token}
    })
}

// 新增文章分类
export function PostBlogClassifyBaseAdd(token,data) {
    return request({
        method: 'post',
        url: '/api/Blog/PostBlogClassifyBaseAdd',
        headers: { 'content-type': 'application/x-www-form-urlencoded','Authorization':'bearer '+token},
        data
    })
}

// 搜索相关文章列表
export function GetBlogSearchList(page,pagesize,content) {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogSearchList',
        headers: { 'content-type': 'application/x-www-form-urlencoded'},
        params:{
            page:page,
            pagesize:pagesize,
            content:content
        }
    })
}

// 浏览记录+1
export function UpdateBrowseCount(bolgid) {
    return request({
        method: 'GET',
        url: '/api/Blog/UpdateBrowseCount',
        headers: { 'content-type': 'application/x-www-form-urlencoded'},
        params:{
            bolgid:bolgid
        }
    })
}

// 获取文章列表
export function GetBlogList(page,pagesize) {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogList',
        headers: { 'content-type': 'application/x-www-form-urlencoded'},
        params:{
            page:page,
            pagesize:pagesize
        }
    })
}

// 获取文章对应标签
export function GetBlogTagsList(blogid) {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogTagsList',
        headers: { 'content-type': 'application/x-www-form-urlencoded'},
        params:{
            blogid:blogid
        }
    })
}

// 获取文章对应分类
export function GetBlogClassifyList(blogid) {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogClassifyList',
        headers: { 'content-type': 'application/x-www-form-urlencoded'},
        params:{
            blogid:blogid
        }
    })
}

// 获取文章对应评论数量
export function GetBlogReplyList(blogid) {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogReplyList',
        headers: { 'content-type': 'application/x-www-form-urlencoded'},
        params:{
            blogid:blogid
        }
    })
}

// 获取所有文章相关的tags
export function GetBlogTagsAllList() {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogTagsAllList',
        headers: { 'content-type': 'application/x-www-form-urlencoded'}
    })
}

// 获取所有文章相关分类
export function GetBlogClasifyAllList() {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogClasifyAllList',
        headers: { 'content-type': 'application/x-www-form-urlencoded'}
    })
}

// 获取热门文章10条
export function GetHotBlogList() {
    return request({
        method: 'GET',
        url: '/api/Blog/GetHotBlogList',
        headers: { 'content-type': 'application/x-www-form-urlencoded'}
    })
}

// 获取最新10条评论
export function GetBlogReply() {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogReply',
        headers: { 'content-type': 'application/x-www-form-urlencoded'}
    })
}

// 随机9条分类
export function GetBlogClassifyBaseRandomList() {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogClassifyBaseRandomList',
        headers: { 'content-type': 'application/x-www-form-urlencoded'}
    })
}

// 随机16条tag
export function GetBlogTagsBaseRandomList() {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogTagsBaseRandomList',
        headers: { 'content-type': 'application/x-www-form-urlencoded'}
    })
}

// 根据文章编号获取内容
export function GetBlogContent(blogid) {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogContent',
        headers: { 'content-type': 'application/x-www-form-urlencoded'},
        params:{
            blogid:blogid
        }
    })
}

// 新增文章评论
export function AddBlogReply(data) {
    return request({
        method: 'post',
        url: '/api/Blog/AddBlogReply',
        headers: { 'content-type': 'application/json'},
        data
    })
}

// 根据文章编号获取评论
export function GetBlogReplyDetal(blogid) {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogReplyDetal',
        headers: { 'content-type': 'application/x-www-form-urlencoded'},
        params:{
            blogid:blogid
        }
    })
}

// 获取留言
export function GetBlogReplyMessage() {
    return request({
        method: 'GET',
        url: '/api/Blog/GetBlogReplyMessage',
        headers: { 'content-type': 'application/x-www-form-urlencoded'}
    })
}

